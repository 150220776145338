import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import MapSelector from '../Map/MapSelector';
import { subLocationsToMarkers } from '../Map/markerHelper';
import LocationCardsWrapper from '../LocationLists/LocationCardsWrapper';
import LocationCard from '../LocationLists/LocationCard';
import MoreRegionsTrailingCard from '../LocationLists/MoreRegionsTrailingCard';
import { useStandortHistory } from '../VHost/StandortHistory';
import Image from '../Image/Image';
import Button from '../form-elements/Button';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { navigateToSubLocation } from '../../../modules/actions/standort/subLocation';
import { canShowGoogleMapsSelector } from '../../../modules/selectors/oneTrust';
import { sortBy } from 'lodash';

function SubLocationFinder() {
    const history = useStandortHistory();
    const canShowGoogleMaps = useSelector(canShowGoogleMapsSelector);
    const markenStandort = useSelector(markenStandortSelector);
    const [selectedCarMake, setSelectedCarMake] = useState('');
    const [showLocationList, setShowLocationList] = useState(false);

    const allLocations = useMemo(() => {
        if (markenStandort.isAreaShop && markenStandort.areaShopSubLocations) {
            return sortBy(markenStandort.areaShopSubLocations, 'anschrift.ort');
        }
        return [markenStandort];
    }, [markenStandort]);

    const locations = useMemo(() => {
        if (selectedCarMake) {
            return allLocations.filter(location => {
                const locationCarMakes = [location.hauptFabrikat, ...location.nebenFabrikat];
                return locationCarMakes.includes(selectedCarMake);
            });
        } else {
            return allLocations;
        }
    }, [allLocations, selectedCarMake]);

    const carMakes = useMemo(
        () => [...new Set([...allLocations.map(item => item.hauptFabrikat), ...allLocations.flatMap(item => item.nebenFabrikat)])],
        [allLocations]
    );

    const markers = useMemo(() => subLocationsToMarkers({ subLocations: locations, history }), [history, locations]);

    const onCareMakeFilterChanged = useCallback(carMake => setSelectedCarMake(carMake), []);

    if (!markenStandort.implementsAreaConcept) return null;

    const logoBackgroundColor = markenStandort.theme?.body?.locationLogoBackground;

    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <h2 className="uk-text-light">In diesen Centern sind wir für Sie da.</h2>
                {canShowGoogleMaps && carMakes.length && (
                    <>
                        {!selectedCarMake && <h3>Bitte wählen Sie eine Marke aus!</h3>}
                        <div className="uk-tile uk-padding-small" style={{backgroundColor:logoBackgroundColor? logoBackgroundColor : "black"}}>
                            <div className="uk-grid uk-grid-small uk-flex uk-flex-center uk-grid-match" data-uk-grid="">
                                {carMakes.map(carMake => (
                                    <div key={carMake}>
                                        <Button
                                            modifier="link"
                                            onClick={() => {
                                                onCareMakeFilterChanged(carMake);
                                            }}
                                        >
                                            <div
                                                className={classnames(
                                                    'uk-tile uk-padding-small uk-height-1-1 uk-flex uk-flex-middle',
                                                    selectedCarMake === carMake ? 'uk-tile-primary' : 'uk-tile-default'
                                                )}
                                            >
                                                <Image
                                                    src={`https://techno-images.s3.eu-central-1.amazonaws.com/images/brand-icons/${carMake}.jpeg`}
                                                    alt={carMake}
                                                    resizeHeight={40}
                                                />
                                                {/* src={`https://technoshopautohaus.blob.core.windows.net/images/brand-icons/${carMake}.jpeg`} */}
                                            </div>
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
                <div className="uk-position-relative">
                    <MapSelector markers={markers} />
                    <div className="uk-position-bottom-right uk-position-medium">
                        <Button modifier="primary" onClick={() => setShowLocationList(!showLocationList)}>
                            {showLocationList ? 'Adressen ausblenden' : 'Adressen einblenden'}
                        </Button>
                    </div>
                </div>
                {showLocationList && (
                    <LocationCardsWrapper>
                        {locations.map((location, index) => (
                            <div key={location._id}>
                                <LocationCard
                                    location={location}
                                    positionNumber={index + 1}
                                    footerChildren={
                                        <Button
                                            modifier="secondary"
                                            fullWidth
                                            onClick={() => {
                                                navigateToSubLocation({ history, subLocationUrl: location.subLocationUrl });
                                            }}
                                        >
                                            MEHR INFOS
                                        </Button>
                                    }
                                />
                            </div>
                        ))}
                        {markenStandort.gesellschafterAreaShopCount > 1 && (
                            <div>
                                <MoreRegionsTrailingCard />
                            </div>
                        )}
                    </LocationCardsWrapper>
                )}
            </div>
        </section>
    );
}

export default SubLocationFinder;

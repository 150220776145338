import { SELECT_SUBLOCATION } from '../../actionTypes';
import { standortUrlSelector } from '../../selectors/url';
import { subLocationToCarBrands } from '../../selectors/standort/subLocation';

export const navigateToSubLocation = ({ history, subLocationUrl, standortUrl: newStandortUrl }) => {
    history.push({ pathname: `/${subLocationUrl}`, newStandortUrl });
};

export const selectSubLocation = ({ subLocation, selectedCarBrand: requestedCarBrand }) => {
    return function (dispatch, getState) {
        const state = getState();
        const standortUrl = standortUrlSelector(state);

        if (!subLocation) {
            return dispatch({
                type: SELECT_SUBLOCATION,
                payload: {
                    standortUrl,
                },
            });
        }

        const carBrands = subLocationToCarBrands(subLocation);

        const isSingleBrandSubLocation = carBrands.length === 1;

        let selectedCarBrand;
        if (isSingleBrandSubLocation) {
            [selectedCarBrand] = carBrands;
        } else if (requestedCarBrand) {
            if (carBrands.includes(requestedCarBrand)) {
                selectedCarBrand = requestedCarBrand;
            } else {
                console.warn('Invalid selectedCarBrand', requestedCarBrand, 'for subLocation', subLocation);
            }
        }

        dispatch({
            type: SELECT_SUBLOCATION,
            payload: {
                standortUrl,
                selectedSublocationUrl: subLocation.subLocationUrl,
                selectedCarBrand,
            },
        });
    };
};

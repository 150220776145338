import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import FallbackMap from './FallbackMap';
import { canShowGoogleMapsSelector } from '../../../modules/selectors/oneTrust';

const GoogleMapWrapper = loadable(() => import('./GoogleMapWrapper'));

function MapSelector({ markers, hideMarkerPosition, maxHeight }) {
    const canShowGoogleMaps = useSelector(canShowGoogleMapsSelector);
    const mapHeight = '50vh';
    return (
        <div className="uk-position-relative uk-width-1-1" style={{ height: mapHeight , maxHeight: maxHeight ? maxHeight : null }}>
            {canShowGoogleMaps ? (
                <GoogleMapWrapper markers={markers} hideMarkerPosition={hideMarkerPosition} />
            ) : (
                <FallbackMap mapHeight={mapHeight} maxHeight={maxHeight ? maxHeight : null} />
            )}
        </div>
    );
}

export default MapSelector;

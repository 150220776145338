import React from 'react';
import { ExternalEventLink } from '../form-elements/Link';
import PropTypes from 'prop-types';

const CalculateRouteLink = ({ children = 'Route berechnen', modifier, hideArrow, label, lat, lng }) => {
    return (
        <ExternalEventLink
            modifier={modifier}
            hideArrow={hideArrow}
            label={label}
            action="Klick-Route-berechnen"
            to={`https://maps.google.com/?daddr=${lat},${lng}`}
        >
            {children}
        </ExternalEventLink>
    );
};

export default CalculateRouteLink;

CalculateRouteLink.propTypes = {
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'reset']),
    hideArrow: PropTypes.bool,
    label: PropTypes.string,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    children: PropTypes.node,
};

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { ExternalEventLink } from '../form-elements/Link';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';

export function ContactPhone({ modifier, hideArrow, phone, hideIcon = false, fullWidth, className = 'uk-flex-center' }) {
    const markenStandort = useSelector(markenStandortSelector);

    const checkedPhone = useMemo(() => {
        if (phone) return phone;
        return markenStandort.anschrift.telefon;
    }, [phone, markenStandort.anschrift.telefon]);

    return (
        <ExternalEventLink
            className={classnames('uk-flex uk-flex-middle', className)}
            modifier={modifier}
            hideArrow={hideArrow}
            fullWidth={fullWidth}
            to={`tel:${checkedPhone}`}
            action="Klick-Telefon"
            category="Anfrage"
            label={checkedPhone}
        >
            {!hideIcon && (
                <>
                    <i className="uk-margin-small-right" data-uk-icon="receiver" />{' '}
                </>
            )}
            {checkedPhone}
        </ExternalEventLink>
    );
}

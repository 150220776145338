import React from 'react';
import Image from '../Image/Image';

function LocationCarMakesRow({ location }) {
    return (
        <div className="uk-grid uk-grid-small uk-child-width-auto uk-flex-middle" data-uk-grid="">
            <div style={{ maxWidth: '25%' }}>
                <Image
                    src={`https://techno-images.s3.eu-central-1.amazonaws.com/images/brand-icons/${location.hauptFabrikat}.jpeg`}
                    alt={location.hauptFabrikat}
                    style={{ maxHeight: 30 }}
                />
                {/* src={`https://technoshopautohaus.blob.core.windows.net/images/brand-icons/${location.hauptFabrikat}.jpeg`} */}

            </div>
            {(location.nebenFabrikat || []).map((nebenFabrikat, index) => (
                <div key={index} style={{ maxWidth: '25%' }}>
                    <Image
                        src={`https://techno-images.s3.eu-central-1.amazonaws.com/images/brand-icons/${nebenFabrikat}.jpeg`}
                        alt={nebenFabrikat}
                        style={{ maxHeight: 30 }}
                    />
                </div>
            ))}
        </div>
    );
}

export default LocationCarMakesRow;

import _ from 'lodash';
import { navigateToSubLocation } from '../../../modules/actions/standort/subLocation';

export function standortToMarker({ standort, onClick }) {
    if (!standort) return null;
    return {
        text: standort.title,
        lat: _.get(standort, 'standort.coordinates[0]'),
        lng: _.get(standort, 'standort.coordinates[1]'),
        onClick,
    };
}

export const subLocationsToMarkers = ({ subLocations, history, standortUrl }) => {
    return subLocations.map(subLocation =>
        standortToMarker({
            standort: subLocation,
            onClick: () => {
                navigateToSubLocation({
                    history,
                    subLocationUrl: subLocation.subLocationUrl,
                    standortUrl,
                });
            },
        })
    );
};

import React from 'react';
import Image from '../Image/Image';
import Button from '../form-elements/Button';

const FallbackMap = ({ mapHeight, maxHeight }) => {
    const showCookieSettings = () => window?.OneTrust?.ToggleInfoDisplay?.();
    return (
        <div>
            <Image
                className="uk-width-1-1"
                style={{ filter: 'blur(8px)', height: mapHeight, maxHeight: maxHeight }}
                src="https://techno-images.s3.eu-central-1.amazonaws.com/images/map/placeholder_de.png"
                alt="Platzhalter Karte"
                resizeWidth={150} // 1200px / 8px (blur)
            />
            <div className="uk-overlay uk-overlay-primary uk-position-center uk-position-medium uk-border-rounded uk-text-center">
                <div className="uk-width-medium@s uk-width-large@m">
                    <Button className="uk-margin-small-bottom" modifier="link" onClick={showCookieSettings}>
                        Um ein Center in der Karte anzeigen zu können, benötigen wir Ihre Einwilligung für FUNKTIONELLE COOKIES in den
                        Cookie-Einstellungen.
                    </Button>
                    <Button modifier="danger" onClick={showCookieSettings}>
                        Cookie-Einstellungen ändern
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FallbackMap;

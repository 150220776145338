import React from 'react';
import { InternalLink } from '../form-elements/Link';
import loadable from '@loadable/component';

const MoreRegionsSVG = loadable(() => import('./MoreRegionsSVG'));

function MoreRegionsTrailingCard() {
    return (
        <div className="uk-card uk-card-small">
            <InternalLink to="/ueberuns" modifier="reset" className="uk-card-primary uk-card-body uk-cover-container">
                <MoreRegionsSVG />
            </InternalLink>
            <div className="uk-card-footer uk-card-default uk-padding-remove uk-margin-auto-top">
                <InternalLink modifier="secondary" fullWidth to="/ueberuns">
                    JETZT ANSEHEN
                </InternalLink>
            </div>
        </div>
    );
}

export default MoreRegionsTrailingCard;

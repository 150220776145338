import React from 'react';

function LocationCardsWrapper({ children }) {
    return (
        <div
            className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
            data-uk-grid=""
            data-uk-height-match="target: > div > .uk-card > .uk-card-body; row: false;"
        >
            {children}
        </div>
    );
}

export default LocationCardsWrapper;

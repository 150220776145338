import React from 'react';
import _ from 'lodash';
import CalculateRouteLink from '../Map/CalculateRouteLink';
import { ContactPhone } from '../Meisterkachel/Contact';
import LocationCarMakesRow from './LocationCarMakesRow';

function LocationCard({ location, positionNumber, footerChildren }) {
    return (
        <div className="uk-card uk-card-default uk-card-small uk-text-left">
            <div className="uk-card-body uk-flex uk-flex-column">
                <div className="uk-grid uk-grid-small">
                    <div className="uk-flex uk-flex-center" style={{ width: 40, minHeight: 1 }}>
                        {positionNumber && <span className="uk-badge">{positionNumber}</span>}
                    </div>
                    <div className="uk-width-expand uk-padding-small uk-padding-remove-vertical">
                        <h5 className="uk-margin-remove">{location.anschrift.firma}</h5>
                    </div>
                </div>
                <div className="uk-grid uk-grid-small">
                    <div className="uk-flex uk-flex-center" style={{ width: 40, minHeight: 1 }} />
                    <div className="uk-width-expand">
                        <LocationCarMakesRow location={location} />
                    </div>
                </div>
                <div className="uk-grid uk-grid-small">
                    <div className="uk-flex uk-flex-center" style={{ width: 40, minHeight: 1 }}>
                        <i className="fa fa-fw fa-2x fa-map-marker" aria-hidden="true" />
                    </div>
                    <div className="uk-width-expand uk-padding-small uk-padding-remove-vertical">
                        <div>{location.anschrift.strasse}</div>
                        <div>
                            {location.anschrift.plz} {location.anschrift.ort}
                        </div>
                        <div>
                            <ContactPhone modifier="reset" hideIcon phone={location.anschrift.telefon} className="uk-flex-left" />
                        </div>
                    </div>
                </div>
                <div className="uk-grid uk-grid-small">
                    <div className="uk-flex uk-flex-center" style={{ width: 40, minHeight: 1 }}>
                        <i className="fa fa-fw fa-2x fa-location-arrow uk-text-primary" />
                    </div>
                    <div className="uk-width-expand uk-padding-small uk-padding-remove-vertical">
                        {location.distance && <div>{location.distance.toUpperCase()} Entfernung</div>}
                        <CalculateRouteLink
                            label={location.url}
                            lat={_.get(location, 'standort.coordinates[0]')}
                            lng={_.get(location, 'standort.coordinates[1]')}
                        />
                    </div>
                </div>
            </div>
            <div className="uk-card-footer uk-padding-remove">{footerChildren}</div>
        </div>
    );
}

export default LocationCard;
